export const logo = [
  '6157 1486',
  `
  <title>Entre Ríos</title>
  <defs>
    <style type="text/css">
      <![CDATA[
        .fil0 {fill:#FEFEFE;fill-rule:nonzero}
      ]]>
    </style>
  </defs>
  <g id="Capa_x0020_1">
    <g id="_1570726599488">
      <path class="fil0" d="M1717 713l0 -357 269 0c70,0 124,15 164,44 40,29 59,73 59,132l0 5c0,54 -19,97 -57,128 -38,32 -93,48 -164,48l-271 0zm601 255c93,-40 167,-97 222,-172 56,-75 83,-170 83,-285l0 -4c0,-35 -2,-69 -8,-101 -3,-17 -6,-34 -10,-50 -21,-77 -59,-142 -113,-195 -50,-51 -114,-91 -193,-119 -80,-28 -177,-42 -291,-42l-431 0 -482 0 -1095 0 0 356 0 1130 641 0 569 0 -240 -351 -224 0 -340 0 0 -235 214 0 488 0 0 -325 -702 0 0 -219 549 0 356 0 0 1130 406 0 0 -450 180 0 299 450 476 0 -354 -518z"/>
      <path class="fil0" d="M3218 900c-48,0 -88,-15 -119,-46 -31,-30 -46,-69 -46,-116l0 0c0,-46 16,-84 47,-115 32,-32 71,-48 118,-48 27,0 50,4 69,11 19,7 37,18 54,32l-43 53c-13,-11 -26,-19 -38,-24 -13,-5 -27,-8 -44,-8 -26,0 -47,9 -64,29 -18,19 -27,42 -27,69l0 1c0,28 9,52 27,71 18,18 41,28 68,28 26,0 48,-6 66,-19l0 -45 -70 0 0 -59 136 0 0 136c-39,33 -84,50 -134,50z"/>
      <path class="fil0" d="M3481 807c18,19 41,29 68,29 28,0 50,-9 68,-28 18,-19 26,-42 26,-70l0 -1c0,-27 -8,-50 -27,-70 -17,-19 -40,-28 -68,-28 -27,0 -50,9 -67,28 -18,19 -27,42 -27,69l0 1c0,28 9,51 27,70zm187 45c-32,32 -72,48 -120,48 -47,0 -87,-16 -119,-47 -31,-31 -47,-70 -47,-115l0 -1c0,-45 16,-83 48,-115 32,-31 71,-47 119,-47 48,0 88,16 119,47 32,31 48,69 48,114l0 1c0,45 -16,84 -48,115z"/>
      <path class="fil0" d="M3953 800l0 -1c0,-23 -17,-34 -51,-34l-80 0 0 69 82 0c32,0 49,-12 49,-34zm-18 -126l0 -1c0,-21 -15,-32 -45,-32l-68 0 0 66 63 0c34,0 50,-11 50,-33zm-180 220l0 -313 145 0c36,0 64,9 82,27 15,15 22,33 22,54l0 1c0,30 -14,53 -42,67 20,8 35,17 44,29 10,12 15,28 15,48l0 1c0,28 -10,50 -31,64 -21,15 -50,22 -86,22l-149 0z"/>
      <polygon class="fil0" points="4059,581 4128,581 4128,894 4059,894 "/>
      <polygon class="fil0" points="4184,894 4184,581 4421,581 4421,642 4253,642 4253,706 4401,706 4401,767 4253,767 4253,833 4423,833 4423,894 "/>
      <path class="fil0" d="M4531 733l70 0c16,0 29,-4 39,-12 9,-8 14,-19 14,-32l0 -1c0,-15 -5,-26 -14,-34 -10,-7 -23,-11 -41,-11l-68 0 0 90zm-69 161l0 -313 143 0c40,0 70,10 92,31 18,18 27,42 27,72l0 1c0,48 -23,80 -68,97l77 112 -81 0 -67 -100 -54 0 0 100 -69 0z"/>
      <polygon class="fil0" points="4763,894 4763,581 4827,581 4974,774 4974,581 5042,581 5042,894 4983,894 4831,695 4831,894 "/>
      <path class="fil0" d="M5180 807c18,19 41,29 68,29 28,0 50,-9 68,-28 18,-19 26,-42 26,-70l0 -1c0,-27 -8,-50 -27,-70 -17,-19 -40,-28 -68,-28 -27,0 -50,9 -67,28 -18,19 -27,42 -27,69l0 1c0,28 9,51 27,70zm187 45c-32,32 -72,48 -120,48 -47,0 -87,-16 -119,-47 -31,-31 -47,-70 -47,-115l0 -1c0,-45 16,-83 48,-115 32,-31 71,-47 119,-47 48,0 88,16 119,47 32,31 48,69 48,114l0 1c0,45 -16,84 -48,115z"/>
      <path class="fil0" d="M5688 643l-53 0 0 189 53 0c28,0 51,-9 68,-26 18,-17 27,-40 27,-68l0 -1c0,-27 -9,-50 -27,-68 -17,-17 -40,-26 -68,-26zm-122 251l0 -313 122 0c49,0 89,14 120,44 31,30 47,67 47,111l0 1c0,45 -16,83 -47,112 -31,30 -71,45 -120,45l-122 0z"/>
      <polygon class="fil0" points="5894,894 5894,581 6130,581 6130,642 5962,642 5962,706 6110,706 6110,767 5962,767 5962,833 6133,833 6133,894 "/>
      <polygon class="fil0" points="3078,1479 3078,1105 3360,1105 3360,1178 3159,1178 3159,1254 3336,1254 3336,1328 3159,1328 3159,1406 3362,1406 3362,1479 "/>
      <polygon class="fil0" points="3409,1479 3409,1105 3485,1105 3660,1335 3660,1105 3741,1105 3741,1479 3672,1479 3490,1241 3490,1479 "/>
      <polygon class="fil0" points="3892,1479 3892,1181 3778,1181 3778,1105 4088,1105 4088,1181 3974,1181 3974,1479 "/>
      <path class="fil0" d="M4206 1287l84 0c20,0 35,-5 46,-14 11,-10 17,-23 17,-39l0 -1c0,-18 -6,-31 -17,-40 -11,-9 -27,-14 -48,-14l-82 0 0 108zm-82 192l0 -374 171 0c48,0 84,13 109,38 22,21 32,50 32,86l0 1c0,57 -26,96 -80,116l92 133 -97 0 -80 -119 -65 0 0 119 -82 0z"/>
      <polygon class="fil0" points="4484,1479 4484,1105 4766,1105 4766,1178 4566,1178 4566,1254 4742,1254 4742,1328 4566,1328 4566,1406 4769,1406 4769,1479 "/>
      <path class="fil0" d="M5031 1287l84 0c20,0 35,-5 46,-14 11,-10 17,-23 17,-39l0 -1c0,-18 -6,-31 -17,-40 -11,-9 -27,-14 -48,-14l-82 0 0 108zm-82 192l0 -374 171 0c47,0 84,13 109,38 21,21 32,50 32,86l0 1c0,57 -27,96 -80,116l91 133 -96 0 -80 -119 -65 0 0 119 -82 0z"/>
      <polygon class="fil0" points="5313,1105 5395,1105 5395,1479 5313,1479 "/>
      <polygon class="fil0" points="5321,1076 5383,995 5451,1024 5381,1076 "/>
      <path class="fil0" d="M5563 1376c22,22 49,34 81,34 33,0 60,-11 81,-34 21,-22 32,-50 32,-83l0 -1c0,-33 -11,-60 -32,-83 -22,-23 -49,-34 -82,-34 -32,0 -59,11 -80,33 -21,23 -32,50 -32,83l0 1c0,33 11,61 32,84zm223 53c-38,38 -86,57 -143,57 -57,0 -104,-19 -142,-56 -37,-37 -56,-83 -56,-137l0 -1c0,-54 19,-99 57,-137 38,-38 85,-56 142,-56 57,0 105,18 142,56 38,37 57,82 57,136l0 1c0,54 -19,100 -57,137z"/>
      <path class="fil0" d="M6020 1485c-62,0 -115,-20 -159,-60l48 -59c38,31 75,46 112,46 17,0 31,-3 40,-9 9,-7 14,-15 14,-26l0 -1c0,-11 -5,-20 -15,-27 -10,-6 -30,-14 -60,-21 -21,-5 -38,-11 -52,-16 -14,-5 -26,-12 -38,-20 -12,-9 -20,-20 -26,-32 -5,-13 -8,-29 -8,-46l0 -1c0,-34 12,-62 36,-82 24,-21 56,-31 95,-31 53,0 99,16 139,47l-43 62c-36,-24 -68,-37 -97,-37 -16,0 -28,3 -36,9 -8,6 -12,14 -12,24l0 1c0,12 5,21 15,27 10,6 32,13 65,22 41,11 71,25 90,41 19,17 29,41 29,72l0 1c0,36 -13,64 -38,85 -25,20 -58,31 -99,31z"/>
    </g>
  </g>
`,
]
